<template>
  <div class="helloword">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="date" label="Date" width="180" align="center" />
      <el-table-column prop="red" label="number" align="center">
        <template #default="scope">
          <span style="letter-spacing: 2px; color: red">{{
            scope.row.red
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin: 15px 0;width:100%; text-align: right;overflow: hidden;">
      <el-pagination
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :background="background"
        layout="prev, pager, next, jumper"
        :total="1000"
        @current-change="handleCurrentChange"
      />
    </div>
    <div style="clear:both;"></div>
    <el-form
      :model="form"
      label-width="auto"
      style="width:100%;"
      @submit.native.prevent
    >
      <el-form-item label="下标">
        <el-input v-model="form.names1" placeholder="用，隔开" clearable />
      </el-form-item>
      <el-form-item label="注数">
        <el-input-number
          v-model="form.number"
          :min="1"
          class="inputFund"
          type="number"
          placeholder=""
          clearable
        />
        <span style="padding-left: 10px">number</span>
        <el-input-number
          v-model="form.number2"
          :min="1"
          class="inputFund"
          type="number"
          placeholder=""
          clearable
          style="margin: 0 15px"
        />
        <el-button
          @click="handleSearch()"
          :disabled="revoe.length == 0 ? true : false"
          >查询</el-button
        >
      </el-form-item>

      <el-form-item label="result">
        <div class="result">
          <span v-for="(item, ind) in form.result" :key="ind">
            {{ item }}
          </span>
        </div>
      </el-form-item>
      <el-form-item :label="'已选'+results.length">
        <div class="result">
          <span v-if="results.length">{{ results }}</span>
        </div>
      </el-form-item>
      <el-form-item :label="'反选'+revoe.length">
        <div class="result">
          <span v-if="revoe.length">{{ revoe }}</span>
        </div>
      </el-form-item>
    </el-form>
    <!-- <div style="margin-bottom: 20px">
      已选({{ results.length }})： {{ results }}
    </div>
    <div>反选({{ revoe.length }})： {{ revoe }}</div> -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      form: {
        name: "",
        names: "",
        names1: "",
        number: 1,
        number2: 1,
        result: [],
      },
      tableData: [],
      results: [],
      revoe: [],
      currentPage: 1,
      pageSize: 10,
    };
  },
  watch: {
    "form.names1"(val) {
      if (!val) {
        this.results = [];
        this.revoe = [];
      }

      if (val && this.tableData.length) {
        setTimeout(() => {
          let vals = val.replace(/，/g, ",").split(",");
          console.log("val", vals);
          this.results = [];
          let results = [];
          vals.map((item) => {
            if (this.tableData[item]) {
              results.push(...this.tableData[item].red.split(","));
            }
          });
          this.results = [...new Set(results)].sort((a, b) => a - b);
          this.revoe = [];
          for (let i = 1; i < 81; i++) {
            let j = i;
            if (j < 10) {
              j = "0" + i;
            }
            if (this.results.indexOf(String(j)) == -1) {
              this.revoe.push(j);
            }
          }
        });
      }
    },
  },
  computed: {},
  created() {
    if (localStorage.getItem("saveData")) {
      this.tableData = JSON.parse(localStorage.getItem("saveData")).result;
      this.form.name = JSON.stringify(
        JSON.parse(localStorage.getItem("saveData"))
      );
    }
  },
  mounted() {
    // console.log("reuslt", jsonp);

    this.$nextTick(()=>{
      this.getTableData();
    })
  },
  methods: {
    handleSearch() {
    
     

      function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }
      let n = this.form.number2;
      this.form.result = [];
      //
      for (let k = 0; k < this.form.number; k++) {
        let a = JSON.parse(JSON.stringify(this.revoe));
        let length = a.length;
        let reuslt = [];
        for (let i = 0; i < n; i++) {
          let y = getRandomInt(0, a.length - 1);
          reuslt.push(Number(a[y]));
          a.splice(y, 1);
        }
        reuslt.sort((a, b) => {
          return a - b;
        });
        this.form.result.push(reuslt);
      }
    },
    getTableData() {
      axios({
        method: "get",
        url: `https://www.cwl.gov.cn/cwl_admin/front/cwlkj/search/kjxx/findDrawNotice?name=kl8&issueCount=&issueStart=&issueEnd=&dayStart=&dayEnd=&pageNo=${this.currentPage}&pageSize=${this.pageSize}&week=&systemType=PC`,
      }).then((res) => {
        this.tableData = res.data.result;
      }).catch((err)=>{
         console.log('err',err);
      })
    },
    handleCurrentChange() {
      this.getTableData();
    },
  },
};
</script>
<style scoped lang="scss">
.hide-arrow .el-input-number__increase,
.hide-arrow .el-input-number__decrease {
  display: none;
}
.helloword{
  padding:8px;
}
.result {
  min-height: 80px;
  max-height: 200px;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  padding: 0 8px;
  display: flex;
  flex-wrap: wrap;
  span{
    margin:5px;
  }
}
</style>